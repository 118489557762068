html, body, #root {
  height: 100%;
}

.html-editor h1 {
  font-size: 2.5rem;
}

.html-editor h2 {
  font-size: 2rem;
}

.html-editor h3 {
  font-size: 1.75rem;
}

.html-editor h4 {
  font-size: 1.5rem;
}

.html-editor h5 {
  font-size: 1.25rem;
}

.html-editor h6 {
  font-size: 1rem;
}

.html-editor .public-DraftStyleDefault-block {
  margin: 0 0 0.5rem 0;
}
